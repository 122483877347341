import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Grid, GridItem, Text } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { CHARGING_PROFILE_TYPE } from '../../../constants/ChargingProfileType'
import { VERSION_STATUS } from '../../../constants/VERSION_STATUS'
import dictionary from '../../../dictionary'
import fetch from '../../../helpers/fetch'
import formateDate from '../../../helpers/formateDate'
import getVersionStatus from '../../../helpers/getVersionStatus'
import Card from '../Card'
import ChargingProfileComment from './ChargingProfileComment'
import EditeFeesData from './EditeFeesData'
import CorporateBundles from './CorporateBundles'
import AITrial from './AITrial'

type Props = {
  versionData: any
  reference?: any
  setGetValues?: any
  disableEditable?: boolean
  actions?: any
}

const VersionDataDetails: FC<Props> = ({ versionData, reference, setGetValues, disableEditable, actions }) => {
  const [bundles, setBundles] = useState<any>([])
  const { corporateId } = useParams<{ corporateId: string; profileId: string }>()
  const { onExtendDialogOpen, OpenStopModal, setAskForAdjustment, onAdjustmentDialogOpen, openModal, getVersionStatus: getStatus, setReject, onOpen } = actions
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm({ mode: 'onChange' })

  // Initialize all the fields values from versionData
  useEffect(() => {
    const fields = [
      'vehicle_subscription_fees',
      'nfc_fees',
      'installation_fees',
      'nfc_replacement_fees',
      'fee_rate',
      'internal_stations_fee_rate',
      'finance_rate',
      'tips_percentage',
      'tips_type',
      'fraud_fees_type',
      'fraud_detection_fees',
      'billing_period',
      'auto_refund',
      'type',
    ]
    fields.forEach((field) => setValue(field, versionData?.[field]))
    setValue('bundle_type', versionData?.bundle_id)
    setValue('ai_trial_enable', versionData?.ai_trial?.ai_trial_enable)
    setValue('ai_trial_number_days', versionData?.ai_trial?.ai_trial_number_days)
  }, [setValue, versionData])

  const getChangedFields = () => {
    return {
      vehicle_subscription_fees: Number(getValues('vehicle_subscription_fees')),
      nfc_fees: Number(getValues('nfc_fees')),
      installation_fees: Number(getValues('installation_fees')),
      nfc_replacement_fees: Number(getValues('nfc_replacement_fees')),
      fee_rate: Number(getValues('fee_rate')),
      internal_stations_fee_rate: Number(getValues('internal_stations_fee_rate')),
      finance_rate: Number(getValues('finance_rate')),
      tips_percentage: Number(getValues('tips_percentage')),
      fraud_detection_fees: Number(getValues('fraud_detection_fees')),
      tips_type: getValues('tips_type'),
      billing_period: getValues('billing_period'),
      auto_refund: getValues('auto_refund'),
      fraud_fees_type: getValues('fraud_fees_type'),
      bundle_id: getValues('bundle_type'),
      ai_trial_enable: ai_trial_enable === true ? true : false,
      ai_trial_number_days: ai_trial_enable === true ? +getValues('ai_trial_number_days') : 0,
    }
  }

  const vehicle_subscription_fees = watch('vehicle_subscription_fees')
  const nfc_fees = watch('nfc_fees')
  const installation_fees = watch('installation_fees')
  const nfc_replacement_fees = watch('nfc_replacement_fees')
  const fee_rate = watch('fee_rate')
  const internal_stations_fee_rate = watch('internal_stations_fee_rate')
  const finance_rate = watch('finance_rate')
  const tips_percentage = watch('tips_percentage')
  const tips_type = watch('tips_type')
  const fraud_detection_fees = watch('fraud_detection_fees')
  const billing_period = watch('billing_period')
  const auto_refund = watch('auto_refund')
  const bundle_type = watch('bundle_type')
  const ai_trial_enable = watch('ai_trial_enable')
  const ai_trial_number_days = watch('ai_trial_number_days')

  useEffect(() => {
    if (setGetValues) setGetValues(getChangedFields())
  }, [
    setGetValues,
    vehicle_subscription_fees,
    nfc_fees,
    installation_fees,
    nfc_replacement_fees,
    fee_rate,
    internal_stations_fee_rate,
    finance_rate,
    tips_percentage,
    tips_type,
    fraud_detection_fees,
    billing_period,
    auto_refund,
    bundle_type,
    ai_trial_enable,
    ai_trial_number_days,
  ])

  const { isLoading, mutate, data } = useMutation(() => fetch('GET', `charging-profile/corporate/${corporateId}/versions`))
  const { mutate: getCorporate, data: corporateData } = useMutation(() => fetch('GET', `corporates/${corporateId}`))

  const { isLoading: isLoadingBundles, mutate: getBundles } = useMutation(() => fetch('GET', '/corporate-bundle'), {
    onSuccess: (data: any): void => {
      // console.log('🚀 ~ file: add.tsx:285 ~ data:', data)
      setBundles(data)
      // onSuccess()
    },
  })

  useEffect(() => {
    getBundles()
    mutate()
    getCorporate()
  }, [])

  const versionDetails = [
    {
      name: dictionary().TYPE,
      value: versionData?.type ? dictionary()[versionData?.type as keyof typeof dictionary] : '-',
    },
    {
      name: dictionary().START_DATE,
      value: versionData?.starts_at ? formateDate(versionData?.starts_at) : '-',
    },
    {
      name: dictionary().END_DATE,
      value: versionData?.expires_at ? formateDate(versionData?.expires_at) : '-',
    },
    {
      name: dictionary().VERSION_STATUS,
      value: getVersionStatus(versionData?.status),
    },
    {
      name: dictionary().TAX_ID,
      value: corporateData?.tax_id,
    },
    {
      name: dictionary().VERSION_CREATOR,
      value: versionData?.creator?.name?.en,
    },
    {
      name: dictionary().VERSION_REVIEWER,
      value: versionData?.reviewer?.name?.en,
    },
    {
      name: dictionary().VERSION_CONFIRMER,
      value: versionData?.confirmer?.name?.en,
    },
    {
      name: dictionary().SIGNED_OF_BY,
      value: versionData?.signer?.name?.en,
    },
  ]

  versionData?.rejection_comment &&
    versionDetails.splice(3, 0, {
      name: dictionary().REJECTION_REASON,
      value: versionData?.rejection_comment,
    })

  const corporateDetails = [
    {
      name: dictionary().CORPORATE_ID,
      value: versionData?.corporate_id,
    },
    {
      name: dictionary().CORPORATES_NAME,
      value: versionData?.corporate?.name,
    },
    {
      name: dictionary().CORPORATES_GROUP,
      value: versionData?.corporate?.corporate_group?.name,
    },
    {
      name: dictionary().INDUSTRY,
      value: versionData?.corporate?.industry,
    },
  ]
  return (
    <Box ref={reference} display={'flex'} flexDirection={'column'} gap={4} id='version-details-container' dir='rtl' textAlign={'right'}>
      {/* Version Details */}
      <Card p={'20px'}>
        <Grid templateColumns='repeat(12, 1fr)' gap={4} pb={4} paddingRight={'20px'}>
          <GridItem colSpan={[12, 12, 12, 12]} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text
              style={{
                fontFamily: 'Noto Kufi Arabic',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '20px',
                textAlign: 'right',
                color: 'rgba(0, 0, 0, 1)',
              }}
              pb={5}
            >
              {`${dictionary().VERSION} ${versionData?.version_number} `}
              {!isLoading && versionData?.id == data?.activeVersion && <span style={{ color: '#6F65DB' }}>{`(${dictionary().LAST_VERSION})`}</span>}
            </Text>
            <div
              style={{
                display:
                  ![VERSION_STATUS.ACTIVE].includes(versionData?.status) ||
                  ![CHARGING_PROFILE_TYPE.PAID_TRIAL, CHARGING_PROFILE_TYPE.FREE_TRIAL].includes(versionData?.type)
                    ? 'none'
                    : 'flex',
                flexDirection: 'row',
                gap: '20px',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: 'white',
              }}
            >
              <Button
                _hover={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
                sx={{
                  height: '32px',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  color: 'rgba(43, 49, 60, 1)',
                  border: '1px solid rgba(208, 213, 221, 1)',
                  padding: '10px, 16px, 10px, 16px',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  onExtendDialogOpen()
                }}
              >
                {/* // {dictionary().SAVE} */}

                <Text
                  style={{
                    fontFamily: 'Cairo',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    textAlign: 'left',
                  }}
                >
                  مد الفترة
                </Text>
              </Button>

              <Button
                _hover={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
                sx={{
                  height: '32px',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  color: '#D32F2F',
                  border: '1px solid rgba(208, 213, 221, 1)',
                  padding: '10px, 16px, 10px, 16px',
                  borderRadius: '8px',
                }}
                onClick={() => OpenStopModal('danger')}
                variant='solid'
              >
                <Text
                  style={{
                    fontFamily: 'Cairo',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    textAlign: 'left',
                  }}
                >
                  إيقاف
                </Text>
              </Button>
            </div>

            <div
              style={{
                display: [VERSION_STATUS.SIGNED_OFF, VERSION_STATUS.REJECTED, VERSION_STATUS.EXPIRED, VERSION_STATUS.ACTIVE].includes(versionData?.status)
                  ? 'none'
                  : 'flex',
                flexDirection: 'row',
                gap: '12px',
                padding: '10px',
                backgroundColor: 'white',
              }}
            >
              {![
                VERSION_STATUS.SIGNED_OFF,
                VERSION_STATUS.REJECTED,
                VERSION_STATUS.EXPIRED,
                VERSION_STATUS.ACTIVE,
                VERSION_STATUS.PENDING_ADJUSTMENTS,
              ].includes(versionData?.status) && (
                <Button
                  _hover={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
                  sx={{
                    height: '32px',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: 'rgba(43, 49, 60, 1)',
                    border: '1px solid rgba(208, 213, 221, 1)',
                    padding: '10px, 16px, 10px, 16px',
                    borderRadius: '8px',
                  }}
                  onClick={() => {
                    setAskForAdjustment(true)
                    onAdjustmentDialogOpen()
                  }}
                  variant='solid'
                >
                  <Text
                    style={{
                      fontFamily: 'Cairo',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      textAlign: 'left',
                    }}
                  >
                    بحاجة للتعديل
                  </Text>
                </Button>
              )}
              {![VERSION_STATUS.SIGNED_OFF, VERSION_STATUS.REJECTED, VERSION_STATUS.EXPIRED, VERSION_STATUS.ACTIVE].includes(versionData?.status) && (
                <Button
                  _hover={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
                  sx={{
                    height: '32px',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: 'rgba(43, 49, 60, 1)',
                    border: '1px solid rgba(208, 213, 221, 1)',
                    borderRadius: '8px',
                  }}
                  onClick={openModal}
                  variant='solid'
                >
                  <Text
                    style={{
                      fontFamily: 'Cairo',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      textAlign: 'left',
                    }}
                  >
                    {getStatus()}
                  </Text>
                </Button>
              )}
              {![VERSION_STATUS.SIGNED_OFF, VERSION_STATUS.REJECTED, VERSION_STATUS.EXPIRED, VERSION_STATUS.ACTIVE].includes(versionData?.status) && (
                <Button
                  _hover={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}
                  sx={{
                    height: '32px',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: '#D32F2F',
                    border: '1px solid rgba(208, 213, 221, 1)',
                    borderRadius: '8px',
                  }}
                  onClick={() => {
                    setReject(true)
                    onOpen()
                  }}
                  variant='solid'
                >
                  <Text
                    style={{
                      fontFamily: 'Cairo',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      textAlign: 'left',
                    }}
                  >
                    رفض
                  </Text>
                </Button>
              )}
            </div>
          </GridItem>

          {versionDetails.map((data, index) => (
            <GridItem colSpan={[8, 3, 3]} key={index}>
              {data?.value && (
                <Box display='flex' flexDirection='column' justifyContent='space-between' gap={'10px'}>
                  <Text
                    style={{
                      fontFamily: 'Cairo',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      textAlign: 'right',
                      color: 'rgba(128, 128, 128, 1)',
                    }}
                  >
                    {data?.name}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Cairo',
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      textAlign: 'right',
                      color: 'rgba(27, 32, 43, 1)',
                    }}
                  >
                    {data.value}
                  </Text>
                </Box>
              )}
            </GridItem>
          ))}
        </Grid>
      </Card>

      {versionData?.comments?.length > 0 && (
        <Card px={5}>
          <GridItem colSpan={[12, 12, 9, 10]}>
            <Accordion defaultIndex={[0]} allowToggle width={'100%'}>
              <AccordionItem>
                <h2>
                  <AccordionButton borderBottom={'0px'} _expanded={{ pb: '19px', borderBottom: '1px solid rgba(226, 232, 240, 1)' }}>
                    <Box as='span' flex='1' textAlign='right'>
                      <Text fontSize='20px' fontWeight='700'>
                        التعليقات
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} width={'100%'}>
                  {versionData?.comments?.map((comment: any, index: number) => (
                    <ChargingProfileComment key={index} comment={comment} />
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </GridItem>
        </Card>
      )}

      {/* Corporate Details */}
      <Card p={5}>
        <Grid templateColumns='repeat(12, 1fr)' gap={4} pb={4}>
          <GridItem colSpan={[12]}>
            <Text
              style={{
                fontFamily: 'Noto Kufi Arabic',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '20px',
                textAlign: 'right',
                color: 'rgba(0, 0, 0, 1)',
              }}
              pb={5}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
            >
              بيانات أساسية
            </Text>
          </GridItem>

          {corporateDetails.map((data, index) => (
            <GridItem colSpan={[8, 4, 4]} key={index}>
              <Box display='flex' flexDirection='column' justifyContent='space-between' gap={'10px'}>
                <Text
                  style={{
                    fontFamily: 'Cairo',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    textAlign: 'right',
                    color: 'rgba(128, 128, 128, 1)',
                  }}
                >
                  {data?.name}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Cairo',
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    textAlign: 'right',
                    color: 'rgba(27, 32, 43, 1)',
                  }}
                >
                  {data.value}
                </Text>
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Card>

      {corporateData && !isLoadingBundles && (
        <CorporateBundles
          watch={watch}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          bundles={bundles}
          disabled={disableEditable}
          bundleId={versionData?.bundle_id}
        />
      )}

      {corporateData && !isLoadingBundles && [1, 2].includes(bundle_type) && (
        <AITrial
          watch={watch}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          bundles={bundles}
          getValues={getValues}
          disabled={disableEditable}
        />
      )}

      {corporateData && (
        <EditeFeesData
          corporate={corporateData}
          versionData={versionData}
          enableEdit={false}
          errors={errors}
          control={control}
          register={register}
          disabled={disableEditable}
          getValues={getValues}
          watch={watch}
        />
      )}
    </Box>
  )
}

export default VersionDataDetails
